<template>
  <div class="distribution-chart-live-controls-mobile">
    <div class="team-logo">
      <img :src="homeTeam ? homeTeam.logo : ''" alt="Equipo local" />
    </div>
    <div>
      <BField>
        <BRadioButton
          v-model="homeTeamSelectedPlaysType"
          :native-value="homeTeamPlaysTypes.history"
          size="is-small"
          type="is-primary"
          :class="{ 'type-selected': homeTeamSelectedPlaysType === 0 }"
        >
          <span class="is-uppercase">hist</span>
        </BRadioButton>
        <BRadioButton
          v-model="homeTeamSelectedPlaysType"
          :native-value="homeTeamPlaysTypes.live"
          size="is-small"
          type="is-primary"
          :disabled="!showLiveData"
          :class="{ 'type-selected': homeTeamSelectedPlaysType === 1 }"
        >
          <span class="is-uppercase">live</span>
        </BRadioButton>
      </BField>
    </div>
    <div>
      <BField>
        <BRadioButton
          v-model="visitingTeamSelectedPlaysType"
          :native-value="visitingTeamPlaysTypes.history"
          size="is-small"
          type="is-primary"
          :class="{ 'type-selected': visitingTeamSelectedPlaysType === 0 }"
        >
          <span class="is-uppercase">hist</span>
        </BRadioButton>
        <BRadioButton
          v-model="visitingTeamSelectedPlaysType"
          :native-value="visitingTeamPlaysTypes.live"
          size="is-small"
          type="is-primary"
          :disabled="!showLiveData"
          :class="{ 'type-selected': visitingTeamSelectedPlaysType === 1 }"
        >
          <span class="is-uppercase">live</span>
        </BRadioButton>
      </BField>
    </div>
    <div class="team-logo">
      <img :src="visitingTeam ? visitingTeam.logo : ''" alt="Equipo local" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaysTypeSelectorMobile',
  components: {
    BField: async () => {
      const { BField } = await import('buefy/dist/esm/field');
      return BField;
    },
    BRadioButton: async () => {
      const { BRadioButton } = await import('buefy/dist/esm/radio');
      return BRadioButton;
    },
  },
  props: {
    homeTeam: {
      type: Object,
      required: true,
    },
    visitingTeam: {
      type: Object,
      required: true,
    },
    showLiveData: {
      type: Boolean,
      default: false,
    },
    homeSelectedType: {
      type: Number,
      default: 0,
    },
    visitingSelectedType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      homeTeamPlaysTypes: {
        history: 0,
        live: 1,
      },
      visitingTeamPlaysTypes: {
        history: 0,
        live: 1,
      },
      homeTeamSelectedPlaysType: this.homeSelectedType,
      visitingTeamSelectedPlaysType: this.visitingSelectedType,
    };
  },
  watch: {
    showLiveData(newValue) {
      if (!newValue) {
        this.homeTeamSelectedPlaysType = 0;
        this.visitingTeamSelectedPlaysType = 0;
      }
    },
    homeTeamSelectedPlaysType(newValue) {
      this.$emit('homeTeamPlaysTypeChanged', newValue);
    },
    visitingTeamSelectedPlaysType(newValue) {
      this.$emit('visitingTeamPlaysTypeChanged', newValue);
    },
  },
};
</script>

<style lang="scss">
.distribution-chart-live-controls-mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 50px;
  & > div {
    margin: 0 4px;
  }
  & span {
    padding-top: 4px;
  }
  & .team-logo {
    height: 26px;
    width: 26px;
    background-color: transparent;
    & > img {
      height: 100%;
    }
  }

  & .type-selected {
    & label.button {
      background-color: #e6eefc !important;
    }
  }
}
@media screen and (max-width: 767px) {
  .distribution-chart-live-controls-mobile {
    bottom: 150px;
  }
}
</style>
