<template>
  <div class="distribution-chard-live-controls">
    <div v-if="showLiveData" class="live-tag is-uppercase">live</div>
    <div class="game-teams">
      <div class="game-team">
        <div class="team-info">
          <div class="team-logo">
            <img :src="homeTeam ? homeTeam.logo : ''" alt="Equipo local" />
          </div>
          <div>
            <span>{{ homeTeam.acronym }}</span>
          </div>
        </div>
        <div>
          <div></div>
          <BField>
            <BRadioButton
              v-model="homeTeamSelectedPlaysType"
              :native-value="homeTeamPlaysTypes.history"
              size="is-small"
              type="is-primary"
              :class="{ 'type-selected': homeTeamSelectedPlaysType === 0 }"
            >
              <span class="is-uppercase">hist</span>
            </BRadioButton>
            <BRadioButton
              v-model="homeTeamSelectedPlaysType"
              :native-value="homeTeamPlaysTypes.live"
              size="is-small"
              type="is-primary"
              :disabled="!showLiveData"
              :class="{ 'type-selected': homeTeamSelectedPlaysType === 1 }"
            >
              <span class="is-uppercase">live</span>
            </BRadioButton>
          </BField>
        </div>
      </div>
      <div class="game-team">
        <div class="team-info">
          <div class="team-logo">
            <img :src="visitingTeam ? visitingTeam.logo : ''" alt="Equipo local" />
          </div>
          <div>{{ visitingTeam.acronym }}</div>
        </div>
        <div>
          <BField>
            <BRadioButton
              v-model="visitingTeamSelectedPlaysType"
              :native-value="visitingTeamPlaysTypes.history"
              size="is-small"
              type="is-primary"
              :class="{ 'type-selected': visitingTeamSelectedPlaysType === 0 }"
            >
              <span class="is-uppercase">hist</span>
            </BRadioButton>
            <BRadioButton
              v-model="visitingTeamSelectedPlaysType"
              :native-value="visitingTeamPlaysTypes.live"
              size="is-small"
              type="is-primary"
              :disabled="!showLiveData"
              :class="{ 'type-selected': visitingTeamSelectedPlaysType === 1 }"
            >
              <span class="is-uppercase">live</span>
            </BRadioButton>
          </BField>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaysTypeSelector',
  components: {
    BField: async () => {
      const { BField } = await import('buefy/dist/esm/field');
      return BField;
    },
    BRadioButton: async () => {
      const { BRadioButton } = await import('buefy/dist/esm/radio');
      return BRadioButton;
    },
  },
  props: {
    homeTeam: {
      type: Object,
      required: true,
    },
    visitingTeam: {
      type: Object,
      required: true,
    },
    showLiveData: {
      type: Boolean,
      default: false,
    },
    homeSelectedType: {
      type: Number,
      default: 0,
    },
    visitingSelectedType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      homeTeamPlaysTypes: {
        history: 0,
        live: 1,
      },
      visitingTeamPlaysTypes: {
        history: 0,
        live: 1,
      },
      homeTeamSelectedPlaysType: this.homeSelectedType,
      visitingTeamSelectedPlaysType: this.visitingSelectedType,
    };
  },
  watch: {
    showLiveData(newValue) {
      if (!newValue) {
        this.homeTeamSelectedPlaysType = 0;
        this.visitingTeamSelectedPlaysType = 0;
      }
    },
    homeTeamSelectedPlaysType(newValue) {
      this.$emit('homeTeamPlaysTypeChanged', newValue);
    },
    visitingTeamSelectedPlaysType(newValue) {
      this.$emit('visitingTeamPlaysTypeChanged', newValue);
    },
  },
};
</script>

<style lang="scss">
.distribution-chard-live-controls {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 12px 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  & span {
    padding-top: 4px;
  }
  & .live-tag {
    padding: 4px 11px 2px;
    margin-bottom: 6px;
    color: white;
    background-color: #77a405;
    border-radius: 4px;
    font-size: 12px;
    line-height: 14px;
  }
  & .game-teams {
    padding: 4px;
    display: flex;
    border-radius: 5px;
    border: solid 1px #c6c6c6;
    & .game-team {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 2px;
      & .team-info {
        display: flex;
        justify-content: center;
        margin-bottom: 4px;
        & .team-logo {
          height: 24px;
          width: 24px;
          margin: 0 9px;
          background-color: transparent;
          & > img {
            height: 100%;
          }
        }
      }
    }
  }

  & .type-selected {
    & label.button {
      background-color: #e6eefc !important;
    }
  }
}
</style>
