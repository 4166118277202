<template>
  <div class="column section-height-dist" v-on:mousemove="updateTooltip($event)">
    <div class="distribution-chart-container" ref="containerChart" :class="isMobile ? 'mobile-dist' : 'desktop'">
      <VueApexCharts
        type="radar"
        :height="height"
        :options="chartOptions"
        :series="series"
        :key="chartKeyCounter + updateCategory"
      />
      <template v-if="showLiveData">
        <PlaysTypeSelector
          v-if="!isMobile"
          :showLiveData="showLiveData"
          :homeTeam="homeTeam"
          :visitingTeam="visitingTeam"
          :homeSelectedType="homeTeamSelectedPlaysType"
          :visitingSelectedType="visitingTeamSelectedPlaysType"
          @homeTeamPlaysTypeChanged="onHomeTeamPlaysTypeChanged"
          @visitingTeamPlaysTypeChanged="onVisitingTeamPlaysTypeChanged"
        />
        <PlaysTypeSelectorMobile
          v-else
          :showLiveData="showLiveData"
          :homeTeam="homeTeam"
          :visitingTeam="visitingTeam"
          :homeSelectedType="homeTeamSelectedPlaysType"
          :visitingSelectedType="visitingTeamSelectedPlaysType"
          @homeTeamPlaysTypeChanged="onHomeTeamPlaysTypeChanged"
          @visitingTeamPlaysTypeChanged="onVisitingTeamPlaysTypeChanged"
        />
      </template>
    </div>
    <ResumeTooltip
      :data="tooltip.data"
      :local="tooltip.local"
      :names="tooltip.names"
      :keyName="tooltip.keyName"
      :leagueAvg="tooltip.leagueAvg"
      :left="tooltip.local"
      :show.sync="tooltip.show"
      :x="tooltip.x"
      :y="tooltip.y"
    />
  </div>
</template>

<script>
import PlaysTypeSelector from '@/components/Elements/NextMatches/PlaysTypeSelector';
import PlaysTypeSelectorMobile from '@/components/Elements/NextMatches/PlaysTypeSelectorMobile';
import ResumeTooltip from '@/components/Elements/NextMatches/ResumeTooltip';
import VueApexCharts from 'vue-apexcharts';
import { mapState, mapGetters } from 'vuex';
import { avg } from '@/utils/league';

const distributionColors = ['#83e730', '#ff832c'];
export default {
  name: 'Distribucion',
  components: {
    VueApexCharts,
    PlaysTypeSelector,
    PlaysTypeSelectorMobile,
    ResumeTooltip,
  },
  data() {
    return {
      height: 509,
      updateCategory: 0,
      homeTeamSelectedPlaysType: 0,
      visitingTeamSelectedPlaysType: 0,
      chartKeyCounter: 0,
      homeTeamPlaysTypes: {
        history: 0,
        live: 1,
      },
      visitingTeamPlaysTypes: {
        history: 0,
        live: 1,
      },
      chartOptions: {
        plotOptions: {
          radar: {
            size: 150,
            polygons: {
              strokeColors: '#dcdcdc',
            },
          },
        },
        chart: {
          type: 'radar',
          height: 509,
          toolbar: {
            show: false,
          },
        },
        colors: distributionColors,
        fill: {
          colors: distributionColors,
          opacity: 0.2,
        },
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'left',
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            style: {
              fontSize: '16px',
              fontFamily: 'Avenir-Demi',
            },
          },
        },
        yaxis: {
          show: false,
        },
        responsive: [
          {
            breakpoint: 770,
            options: {
              plotOptions: {
                radar: {
                  size: 100,
                  offsetY: 50,
                  polygons: {
                    strokeColors: '#dcdcdc',
                  },
                },
                bar: {
                  horizontal: false,
                },
              },
              legend: {
                show: 'bottom',
              },
              xaxis: {
                labels: {
                  show: true,
                  style: {
                    fontSize: '14px',
                  },
                },
              },
            },
          },
          {
            breakpoint: 450,
            options: {
              plotOptions: {
                radar: {
                  size: 70,
                  offsetY: -20,
                  polygons: {
                    strokeColors: '#dcdcdc',
                  },
                },
                bar: {
                  horizontal: false,
                },
              },
              legend: {
                show: 'bottom',
              },
              xaxis: {
                labels: {
                  show: true,
                  style: {
                    fontSize: '12px',
                  },
                },
              },
            },
          },
        ],
        tooltip: {
          intersect: true,
          shared: false,
          custom: ({ w, dataPointIndex, seriesIndex }) => {
            this.showModal(w, dataPointIndex, seriesIndex);
            return null;
          },
        },
      },
      tooltip: {
        show: false,
        data: {},
        names: {},
        keyName: '',
        leagueAvg: '',
        showLiveData: false,
        local: false,
        x: 0,
        y: 0,
      },
      globals: {
        x: 0,
        y: 0,
      },
    };
  },
  watch: {
    menuOptionActive() {
      if (this.idAssetSelected == 3) {
        setTimeout(() => {
          var existElement = document.getElementsByClassName('apexcharts-svg');
          if (existElement.length > 0) {
            document.getElementsByClassName('apexcharts-svg')[0].style.width = 788 + 'px';
          }
        }, 400);
        this.updateCategory++;
      }
    },
    idAssetSelected() {
      if (this.idAssetSelected == 3) {
        this.chartKeyCounter++;
      }
    },
    subcategoriesNames: {
      immediate: true,
      handler(newValue) {
        this.chartOptions.xaxis.categories = newValue;
        this.updateCategory++;
        if (this.idAssetSelected == 3) {
          setTimeout(() => {
            var existElement = document.getElementsByClassName('apexcharts-svg');
            if (existElement.length > 0) {
              document.getElementsByClassName('apexcharts-svg')[0].style.width = 788 + 'px';
            }
          }, 400);
        }
      },
    },
    isMobile(newValue) {
      this.chartOptions.legend.horizontalAlign = newValue ? 'center' : 'left';
      this.chartKeyCounter++;
    },
  },
  computed: {
    ...mapState('nextGeneral', ['showLiveData']),
    ...mapGetters('nextGeneral', [
      'idAssetSelected',
      'statisticData',
      'menuOptionActive',
      'homeTeamStatisticsType',
      'showPostMatchData',
      'visitingTeamStatisticsType',
      'homeTeam',
      'visitingTeam',
      'subcategoryName',
      'accomplished',
    ]),
    isMobile() {
      return this.$mq === 'mobile';
    },
    series() {
      let local;
      let visit;
      if (!this.showPostMatchData) {
        local = {
          name: `${this.homeTeamSelectedPlaysType === 0 ? 'Histórico' : 'Live'} ${this.homeTeam.acronym}`,
          data: [],
        };
        visit = {
          name: `${this.visitingTeamSelectedPlaysType === 0 ? 'Histórico' : 'Live'} ${this.visitingTeam.acronym}`,
          data: [],
        };
        Object.values(this.statisticData).forEach(subcategory => {
          let homeTeamCategoryData;
          let visitingTeamCategoryData;
          const homeLeaderAvg = subcategory.homeTeam[this.homeTeamStatisticsType].leader.points;
          const visitingLeaderAvg = subcategory.visitingTeam[this.visitingTeamStatisticsType].leader.points;
          if (this.homeTeamSelectedPlaysType === 0) {
            homeTeamCategoryData = homeLeaderAvg
              ? subcategory.homeTeam[this.homeTeamStatisticsType].avgPostGame / homeLeaderAvg
              : 0;
          } else {
            const totalLive =
              subcategory.homeTeam[this.homeTeamStatisticsType].totalLive +
              subcategory.visitingTeam[this.visitingTeamStatisticsType].totalLive;
            homeTeamCategoryData = totalLive
              ? subcategory.homeTeam[this.homeTeamStatisticsType].totalLive / totalLive
              : 0;
          }
          if (this.visitingTeamSelectedPlaysType === 0) {
            visitingTeamCategoryData = visitingLeaderAvg
              ? subcategory.visitingTeam[this.visitingTeamStatisticsType].avgPostGame / visitingLeaderAvg
              : 0;
          } else {
            const totalLive =
              subcategory.homeTeam[this.homeTeamStatisticsType].totalLive +
              subcategory.visitingTeam[this.visitingTeamStatisticsType].totalLive;
            visitingTeamCategoryData = totalLive
              ? subcategory.visitingTeam[this.visitingTeamStatisticsType].totalLive / totalLive
              : 0;
          }
          local.data.push(Math.round(120 * homeTeamCategoryData));
          visit.data.push(Math.round(120 * visitingTeamCategoryData));
        });
      } else {
        local = {
          name: `${this.homeTeam.acronym}`,
          data: [],
        };
        visit = {
          name: `${this.visitingTeam.acronym}`,
          data: [],
        };
        Object.values(this.statisticData).forEach(subcategory => {
          const totalPostMatch =
            subcategory.homeTeam[this.homeTeamStatisticsType].totalPostGame +
            subcategory.visitingTeam[this.visitingTeamStatisticsType].totalPostGame;
          local.data.push(
            Math.round(
              120 *
                (totalPostMatch ? subcategory.homeTeam[this.homeTeamStatisticsType].totalPostGame / totalPostMatch : 0),
            ),
          );
          visit.data.push(
            Math.round(
              120 *
                (totalPostMatch
                  ? subcategory.visitingTeam[this.visitingTeamStatisticsType].totalPostGame / totalPostMatch
                  : 0),
            ),
          );
        });
      }
      return [local, visit];
    },
    subcategoriesNames() {
      return Object.values(this.statisticData).map((subcategory, index) => {
        return this.prepareLabels(subcategory, index);
      });
    },
    /**
     * @author José Rafael Gutierrez
     * @return Array
     */
    keysIndexes() {
      return Object.keys(this.statisticData);
    },
  },
  methods: {
    onHomeTeamPlaysTypeChanged(newType) {
      this.homeTeamSelectedPlaysType = newType;
    },
    onVisitingTeamPlaysTypeChanged(newType) {
      this.visitingTeamSelectedPlaysType = newType;
    },
    /**
     * @author José Rafael Gutierrez
     * @param w
     * @param index
     * @param teamIndex
     * @return void
     */
    showModal(w, index, teamIndex) {
      const TEAM_TYPE = teamIndex === 0 ? 'homeTeam' : 'visitingTeam';
      const STATISTIC_TYPE = teamIndex === 0 ? this.homeTeamStatisticsType : this.visitingTeamStatisticsType;
      const DATA = this.statisticData[this.keysIndexes[index]];
      this.tooltip.data = DATA[TEAM_TYPE][STATISTIC_TYPE];
      this.tooltip.local = teamIndex === 0;
      this.tooltip.names = DATA.names;
      this.tooltip.keyName = this.subcategoryName;
      this.tooltip.leagueAvg = this.leagueAvg(DATA);
      const X = w.globals.clientX || 0;
      const Y = w.globals.clientY || 0;
      this.globals.x = X;
      this.globals.y = Y;
      this.tooltip.x = X;
      this.tooltip.y = Y > 700 ? Y - 250 : Y;
      this.tooltip.show = true;
    },
    /**
     * @author José Rafael Gutierrez
     * @param event
     * @return void
     */
    updateTooltip(event) {
      const DIFF_MAX = 5;
      const DIFF_X = Math.abs(event.clientX - this.globals.x);
      const DIFF_Y = Math.abs(event.clientY - this.globals.y);
      if (DIFF_X > DIFF_MAX || DIFF_Y > DIFF_MAX) this.tooltip.show = false;
    },
    prepareLabels(subcategory, index) {
      let label = subcategory.names[this.subcategoryName] || subcategory.names['0-0'];
      if (index === 0) return label;
      const LIMIT = 25;
      if (label.length > LIMIT) {
        let fragments = label.split(' ');
        label = [];
        let temp = '';
        let result = '';
        let lastFragment = fragments.slice(-1)[0];
        fragments.forEach(fragment => {
          temp += ` ${fragment} `;
          if (temp.length > LIMIT) {
            label.push(result.trim());
            temp = fragment;
          }
          result = temp;
          if (lastFragment === fragment) {
            label.push(result.trim());
          }
        });
      }
      return label;
    },
    leagueAvg(subcategory) {
      return avg(subcategory, this.accomplished);
    },
  },
};
</script>

<style lang="scss">
.distribution-chart-container {
  position: relative;
  height: 100%;
}
.section-height-dist {
  height: 560px;
  margin-top: -55px;
  align-self: center;
  z-index: 1;
}
.mobile-dist {
  margin-top: -10px;
}
@media screen and (min-width: 768px) and (max-width: 769px) {
  .distribution-chart-live-controls {
    bottom: 90px !important;
  }
  .distribution-chart-container {
    .apexcharts-legend.position-bottom.apexcharts-align-left {
      margin-bottom: 65px !important;
    }
  }
}
@media screen and (max-width: 767px) {
  .distribution-chart-container {
    .apexcharts-legend.position-bottom.apexcharts-align-center,
    .apexcharts-legend.position-bottom.apexcharts-align-left {
      margin-bottom: 85px !important;
      justify-content: center !important;
    }
  }
}
@media screen and (max-width: 400px) {
  .section-height-dist {
    height: 550px;
    margin-top: -100px;
  }
}
</style>
